module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-theme-ui-virtual-2d20b39d53/3/buildhome/.yarn/berry/cache/gatsby-plugin-theme-ui-npm-0.16.2-4505aaf398-10c0.zip/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-analytics-virtual-a3f4fd4a70/3/buildhome/.yarn/berry/cache/gatsby-plugin-google-analytics-npm-5.13.1-b252bbe12e-10c0.zip/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-3632655-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-1d03e8721f/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
