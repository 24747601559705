exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-478-index-tsx": () => import("./../../../src/pages/478/index.tsx" /* webpackChunkName: "component---src-pages-478-index-tsx" */),
  "component---src-pages-affirmations-index-tsx": () => import("./../../../src/pages/affirmations/index.tsx" /* webpackChunkName: "component---src-pages-affirmations-index-tsx" */),
  "component---src-pages-ascii-tsx": () => import("./../../../src/pages/ascii.tsx" /* webpackChunkName: "component---src-pages-ascii-tsx" */),
  "component---src-pages-blog-ai-image-prompts-mdx": () => import("./../../../src/pages/blog/ai-image-prompts.mdx" /* webpackChunkName: "component---src-pages-blog-ai-image-prompts-mdx" */),
  "component---src-pages-blog-css-3-selection-background-color-trick-tsx": () => import("./../../../src/pages/blog/css3-selection-background-color-trick.tsx" /* webpackChunkName: "component---src-pages-blog-css-3-selection-background-color-trick-tsx" */),
  "component---src-pages-blog-design-engineering-mdx": () => import("./../../../src/pages/blog/design-engineering.mdx" /* webpackChunkName: "component---src-pages-blog-design-engineering-mdx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-my-gpts-mdx": () => import("./../../../src/pages/blog/my-gpts.mdx" /* webpackChunkName: "component---src-pages-blog-my-gpts-mdx" */),
  "component---src-pages-blog-return-to-vim-mdx": () => import("./../../../src/pages/blog/return-to-vim.mdx" /* webpackChunkName: "component---src-pages-blog-return-to-vim-mdx" */),
  "component---src-pages-blog-simple-vertical-centering-with-css-tsx": () => import("./../../../src/pages/blog/simple-vertical-centering-with-css.tsx" /* webpackChunkName: "component---src-pages-blog-simple-vertical-centering-with-css-tsx" */),
  "component---src-pages-blog-time-for-a-change-tsx": () => import("./../../../src/pages/blog/time-for-a-change.tsx" /* webpackChunkName: "component---src-pages-blog-time-for-a-change-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog/[...].tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-blog-video-and-audio-in-css-tsx": () => import("./../../../src/pages/blog/video-and-audio-in-css.tsx" /* webpackChunkName: "component---src-pages-blog-video-and-audio-in-css-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-profile-mdx": () => import("./../../../src/pages/profile.mdx" /* webpackChunkName: "component---src-pages-profile-mdx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects/[...].tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-speaking-tsx": () => import("./../../../src/pages/speaking.tsx" /* webpackChunkName: "component---src-pages-speaking-tsx" */),
  "component---src-pages-system-ui-characters-tsx": () => import("./../../../src/pages/system-ui/characters/[...].tsx" /* webpackChunkName: "component---src-pages-system-ui-characters-tsx" */),
  "component---src-pages-system-ui-index-tsx": () => import("./../../../src/pages/system-ui/index.tsx" /* webpackChunkName: "component---src-pages-system-ui-index-tsx" */)
}

